import React, { useEffect, useState } from "react";
import SliderComp from "../components/SliderComp";
import "../Css/whoweare.css";
import StudentsOpinions from "../components/StudentsOpinions.js";
import PurpleBox from "../components/PurpleBox.js";
import axios from "axios";
import { useLocation } from "react-router-dom";
function WhoWeAre() {

  const [basmatrainig, setBasmaTraining] = useState([]);
  const [whoweare, setWhoweare] = useState([]);
  const [slider, setSlider] = useState([]);
  const [aboutteacher, setAboutTeacher] = useState([]);

  const location = useLocation();
  const page = location.pathname;
  // const fetchSlider = async () => {
  //   try {
  //     const response = await axios.get(
  //       `https://ba9maacademy.kasselsoft.online/sliders/${page}`
  //     );
  //     const data = response.data;
  //     setSlider(data); // Assuming setTags is a function to update your state
  //   } catch (error) {
  //     console.error("Failed to fetch slider:", error);
  //   }
  // };

  // fetchSlider();
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   fetchSlider(page).then((data) => setSlider(data)); // Assuming data is an array
  // }, [page]);
  const fetchBasma = async () => {
    try {
      const response = await axios.get(
        "https://ba9maacademy.kasselsoft.online/basmatrainig/basmatrainigbyid/2"
      );
      const data = response.data;
      setBasmaTraining(data);
    } catch (error) {
      console.log(`Error getting data from frontend: ${error}`);
    }
  };
  const fetchWhoweare = async () => {
    try {
      const response = await axios.get(
        "https://ba9maacademy.kasselsoft.online/whoweare"
      );
      const data = response.data;
      setWhoweare(data);
      console.log("basma training", data[0].title);
    } catch (error) {
      console.log(`Error getting data from frontend: ${error}`);
    }
  };
  const fetchAboutTeacher = async () => {
    try {
      const response = await axios.get(
        "https://ba9maacademy.kasselsoft.online/aboutteacher"
      );
      setAboutTeacher(response.data);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchBasma();
    fetchWhoweare();
    fetchAboutTeacher();
  }, []);
  const title = "من نحن";
  const description =
    "في عصرنا الرقمي، تحولت التكنولوجيا إلى عنصر أساسي في حياتنا اليومية، ومعها، ظهر التعليم عن بُعد كوسيلة مثالية لتكميل النظام التعليمي التقليدي. هذه الطريقة الجديدة تمكن الطلاب من فهم المفاهيم المعقدة بسهولة أكبر";
    const { hash } = useLocation();

    useEffect(() => {
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }, [hash]);
  
    return (
    <>
      <SliderComp slider={slider} />
      {/* <SliderComp title={title} description={description} /> */}

      {/* slider box */}
      <div className="container  slider_box">
        <div className="row">
          {whoweare.map((who, index) => (
            <div className="col-lg-4 col-md-12 col-sm-12 p-1"key={who.id}>
            <div className="d-flex">
              {/* <div className="vector_cont">
                <h1 className="" style={{ color: "#fff" }}>
                  {index + 1}
                </h1>
              </div> */}
              <p className="description_of_steps_who">
{who.title}              </p>
            </div>
          </div>
          ))}
        </div>
        <div className="row d-flex justify-content-center"></div>
      </div>
      {/* End slider box */}
      {/* Our Teachear */}
      <div className="container text-center about-section"id="order-section">
        {aboutteacher.map((tech) => (
          <div className="row ">
            <div className="col-lg-5 col-md-6 col-sm-12">
              <h2 className="about_title">{tech.title}</h2>
              <p className="p_about">{tech.descr}</p>
            </div>{" "}
            <div className="col-lg-7 col-md-6 col-sm-12 ">
              <img
                src={`https://ba9maacademy.kasselsoft.online/${tech.img}`}
                alt="about"
                className="about_img img-fluid "
              />
            </div>
          </div>
        ))}
      </div>
      {/* End Our Teachear */}
      <StudentsOpinions />
      {/* some paragraph */}
      <div className="margin_section">
        <div className="container w-75 pt-4">
          {aboutteacher.map((tech) => (
            <p className="paragraph_who">{tech.para}</p>
          ))}
        </div>
      </div>
      {/* End some paragraph */}

      {basmatrainig.length === 0 ? (
        <div>Loading...</div> // Display loading state
      ) : (
        basmatrainig.map((item) => (
          <PurpleBox
            key={item.id} // Unique key for each item
            title={item.title}
            description={item.descr}
            link="/courses" // Adjust link if needed
          />
        ))
      )}
    </>
  );
}

export default WhoWeAre;
